.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

p {
    margin: 8px;
}

p.text {
    font-size: 18px;
}

.hoverable {
    cursor: pointer;
}

.vl-button {
    border-radius: 16px !important;
    color: white !important;
}

.vl-input {
    border-radius: 8px;
    outline: none !important;
    border: 1px solid grey;
    padding: 8px
}

.vl-input:focus {
    border: 1px solid #E39923;
}

.header {
    font-weight: 600;
    font-size: 40px;
}

.wide {
    width: 100%;
}

.top8 {
    margin-top: 8px !important;
}

.vl-theme {
    background: #E39923 !important;
    color: white !important;
}

.nomargin {
    margin: 0 !important;
}

.dropdown-content {
    display: none;
    position: absolute;

    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.noselect {
    user-select: none !important;
}

.menuItem :hover {
    background: #ddd;
    color: #E39923;
}

.magnifying-glass {
    z-index: 3000 !important;
}

.vl-text {
    color: #E39923
}

.vl-border {
    border: 2px solid #E39923 !important;
    border-radius: 8px;
    padding: 8px;
}


.form-row {
    display: flex;
    padding: 8px;
    justify-content: flex-start;
}

.form-row p {
    width: 200px;
    margin: 0;
}

.form-row input {
    width: 400px;
    padding: 2px!important;;
}

.form-row select {
    width: 400px;
}

.noselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}